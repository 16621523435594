import cn from 'classnames'
import { Field, FieldProps, Form, useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import IsReturnTripField from '@components/IsReturnTrip/Field'
import PassengersDropdown from '@components/PassengersDropdown'
import BookingDiscounts from '@components/SearchForm/BookingDiscounts'
import DatesFieldSet from '@components/SearchForm/DatesFieldSet'
import DepartureTimeField from '@components/SearchForm/DepartureTimeField'
import { SearchFormSettings, SearchFormState } from '@components/SearchForm/index'
import LocationFieldSet from '@components/SearchForm/LocationFieldSet'
import config from '@config'
import useValidators from '@hooks/useValidators'
import { useTranslation } from '@lib/i18n'
import { useDiscounts } from '@queries/dicounts'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Button } from '@ui'
import CheckboxField from '@ui/Checkbox/Field'

const LAYOUT_CLASS_NAMES = {
  row: {
    container: ['row', 'gap-3', 'wrap'],
    locationsItem: ['cell-4', 'grow', 'cell-md-12'],
    tripTypeContainer: ['cell-12', '-order-1'],
    datePickerContainer: ['cell-3', 'grow'],
    timeContainer: ['cell-2', 'grow'],
    passengersContainer: ['cell-2', 'grow'],
    discountsContainer: ['cell-2', 'grow'],
    submitContainer: ['cell'],
    accommodationContainer: ['cell-12', 'order-6'],
  },
  'row-2': {
    container: ['row', 'gap-3', 'wrap'],
    locationsItem: ['cell-8', 'grow', 'order-1'],
    tripTypeContainer: ['cell-12', '-order-1'],
    datePickerContainer: ['cell-4', 'grow', 'order-3'],
    timeContainer: ['cell-4', 'grow', 'order-3'],
    passengersContainer: ['cell-4', 'grow', 'order-2'],
    discountsContainer: ['cell-4', 'grow', 'order-4'],
    submitContainer: ['cell-12', 'cell-lg-4', 'order-5'],
    accommodationContainer: ['cell-12', 'order-4', 'order-lg-6'],
  },
  column: {
    container: ['column', 'gap-5'],
    locationsItem: ['cell'],
    tripTypeContainer: ['cell'],
    datePickerContainer: ['cell'],
    timeContainer: ['cell'],
    passengersContainer: ['cell'],
    discountsContainer: ['cell'],
    submitContainer: ['cell', 'mt-1'],
    accommodationContainer: ['cell', 'mt-1'],
  },
}

interface FormContentProps extends Directional {
  passengerList: Passenger.Type[]
  settings: SearchFormSettings
  isDepartureDateDisabled?: (date: Date) => boolean
}

const FormContent = (props: FormContentProps): ReactElement => {
  const { settings, direction = 'row', passengerList, isDepartureDateDisabled } = props
  const { t } = useTranslation()
  const [{ passengerTypesList }] = useSettings()
  const [{ retailerPartnerNumber }] = useParams()
  const { values } = useFormikContext<SearchFormState>()
  const { passengers } = useValidators()
  const discounts = useDiscounts({ enabled: settings.paxDiscountCodes || settings.discountCodes })

  const classNames = useMemo(() => {
    if (direction === 'column') return LAYOUT_CLASS_NAMES[direction]

    const extraFieldsAmount =
      Object.values(settings).filter(enabled => enabled).length - Number(settings?.accommodation ?? 0)

    if (extraFieldsAmount >= 3) return LAYOUT_CLASS_NAMES['row-2']

    return LAYOUT_CLASS_NAMES.row
  }, [direction, settings])

  const handleMaxCount = (totalCount: number): number =>
    totalCount >= config.amtrakMaxPassengers ? 0 : config.amtrakMaxPassengers

  const handleValidate = (value: Passenger.Param[]): string | undefined => passengers(value, retailerPartnerNumber)

  return (
    <Form id="search-form">
      <div className={cn('search-form', ...classNames.container)}>
        <div className={cn('search-form__locations', ...classNames.locationsItem)}>
          <LocationFieldSet direction={direction} />
        </div>
        {settings.returnTrip && (
          <div className={cn('search-form__trip-direction-radio-group', ...classNames.tripTypeContainer)}>
            <Field component={IsReturnTripField} name="isReturnTrip" />
          </div>
        )}
        <div className={cn(...classNames.datePickerContainer)}>
          <DatesFieldSet isDepartureDateDisabled={isDepartureDateDisabled} />
        </div>
        {settings.tripTime && (
          <div className={cn(...classNames.timeContainer)}>
            <DepartureTimeField />
          </div>
        )}
        {settings.passengerType && (
          <div className={cn(...classNames.passengersContainer)}>
            <Field name="passengers" validate={handleValidate}>
              {({ field: { value, name }, form: { setFieldValue, errors } }: FieldProps) => (
                <PassengersDropdown
                  label={t('searchBar.passengers.label')}
                  value={value}
                  onChange={passengers => {
                    setFieldValue(name, passengers)
                  }}
                  availableTypes={passengerTypesList.enabled ? passengerList : null}
                  getMaxCount={passengerTypesList.enabled ? handleMaxCount : null}
                  errors={errors}
                  discounts={discounts}
                  showDiscounts={settings.paxDiscountCodes}
                  showAge={passengerTypesList.multiple}
                />
              )}
            </Field>
          </div>
        )}
        {settings.discountCodes && <BookingDiscounts classNames={classNames} discounts={discounts} />}
        {settings.accommodation && (
          <div className={cn(...classNames.accommodationContainer, 'search-form__accommodation')}>
            <Field
              component={CheckboxField}
              checked={values.accommodation}
              name="accommodation"
              label={t('searchBar.accommodation')}
            />
          </div>
        )}
        <div className={cn('search-form__submit-button', ...classNames.submitContainer)}>
          <Button buttonType="submit">{t('searchBar.search')}</Button>
        </div>
      </div>
    </Form>
  )
}

export default FormContent
